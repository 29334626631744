<template>
  <div class="dashboard challenges-list-page">
    <div class="button-toggle-wrapper section-padding d-xl-none">
      <div class="container d-flex justify-content-center align-items-center">
        <div class="button-toggle active-1">
          <button type="button" class="button-toggle-btn active" @click="challengesButtonToggle" rel="challenges">Desafios</button>
          <button type="button" class="button-toggle-btn" @click="challengesButtonToggle" rel="ranking">Ranking</button>
        </div>
      </div>
    </div>
    <div class="challenges-list">
      <div class="container">
        <div class="row">
          <div class="col-12 col-xl-8 challenges-list-column active" rel="challenges">
            <p class="section-title d-none d-xl-block">Desafios</p>
            <Slick ref="slick" :options="slickOptions" class="challenges-list-slider" v-if="challengesListItems.length > 0">
              <div class="challenges-list-slider-item" v-for="(item,index) in challengesListItems" :key="index">
                <router-link :to="{ name: 'challenges-detail', params:{id:$root.getLink(item)}}">
                  <div class="challenges-list-image">
                    <img :src="item.media.general[0].image" alt="">
                  </div>
                  <div class="challenges-list-slider-item-content">
                    <p class="challenges-list-slider-item-title">{{ item.title }}</p>
                    <p class="challenges-list-slider-item-subtitle" v-if="item.isActive">A Decorrer…</p>
                    <p class="challenges-list-slider-item-subtitle" v-else>Terminado</p>
                    <button type="button" class="generic-btn red red-shadow big-text challenges-list-slider-item-btn">Participar</button>
                  </div>
                </router-link>
              </div>
            </Slick>
          </div>
          <div class="col-12 col-xl-4 challenges-list-column" rel="ranking">
            <div class="container">
              <p class="section-title d-none d-xl-block">Ranking</p>
              <div class="challenges-list-ranking">
                <div class="select-wrapper">
                  <FormulateInput type="select" name="challengeFilter" v-model="challengeFilter" :options="{0:'Selecione um Estado',1: 'Desafios terminados', 2: 'Desafios a decorrer'}"  @change="changeChallengeFilter"/>
                </div>
                <div class="challenges-list-ranking-list">
                  <div class="row challenges-list-ranking-list-row">
                    <div class="col-12 col-md-6 col-xl-12 challenges-list-ranking-list-column"  v-for="(item,index) in challengesListItems" :key="index">
                      <div class="challenges-list-ranking-list-item" v-if="!!item.media.general">
                        <router-link :to="{ name: 'challenges-detail', params:{id:$root.getLink(item)}}" >
                          <div class="challenges-list-ranking-list-item-image">
                            <img :src="item.media.general[0].image" alt="">
                          </div>
                          <div class="challenges-list-ranking-list-item-content d-flex justify-content-between align-items-start flex-column">
                            <div class="challenges-list-ranking-list-item-position">
                              <p class="challenges-list-ranking-list-item-position-title">100</p>
                              <p class="challenges-list-ranking-list-item-position-subtitle">Posição</p>
                            </div>
                            <div class="challenges-list-ranking-list-item-text d-flex justify-content-between align-items-center">
                              <div>
                                <p class="challenges-list-ranking-list-item-title">{{ item.title }}</p>
                                <p class="challenges-list-ranking-list-item-subtitle" v-if="item.isActive">A Decorrer…</p>
                                <p class="challenges-list-ranking-list-item-subtitle" v-else>Terminado</p>
                              </div>
                              <div class="challenges-list-ranking-list-item-btn">
                                <plusIcon/>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slick from "vue-slick";
import plusIcon from "@/components/svg/plus.vue";
import axios from "axios";

export default {
  name: "challenges-list",
  components : {
    Slick,
    plusIcon
  },
  data: function () {
    return {
      lang: this.$root.siteLang,
      challengesListItems: [],
      showChallengesList: false,
      challengeFilter: 0,
      slickOptions: {
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: true,
        arrows: false,
        dots: true,
        infinite: true,
        swipeToSlide: true,
        touchMove: true,
        speed: 1000,
      }
    }
  },
  metaInfo () {
    return {
      title: this.$root.translations.desafios,
    }
  },
  methods: {
    challengesButtonToggle(e) {
      let target = e.currentTarget;
      let targetIndex = Array.from(target.parentElement.children).indexOf(target) + 1;
      let tabID = target.getAttribute('rel');
      let selectedContent = document.querySelectorAll('.challenges-list-column[rel="'+ tabID +'"]')[0];

      let parent = target.closest('.button-toggle');

      parent.classList.remove('active-1');
      parent.classList.remove('active-2');
      parent.classList.add('active-' + targetIndex);

      let buttons = Array.from(document.querySelectorAll('.button-toggle-btn'));
      let contents = Array.from(document.querySelectorAll('.challenges-list-column'));

      if ( ! target.classList.contains('active') ) {
        buttons.forEach(node => {
          node.classList.remove('active');
        });

        contents.forEach(node => {
          node.classList.remove('active');
        });

        target.classList.add('active');
        selectedContent.classList.add('active');
      }
    },
    changeChallengeFilter() {
      let filter = '';
      let authKey = window.$cookies.get("authKey");

      if ( this.challengeFilter != '' && this.challengeFilter != undefined ) {
        let filter = '&status=' + this.challengeFilter;

        console.log(filter);
        axios
            .get(process.env.VUE_APP_API_URL + 'products?id_category=4' + filter + '&lang=' + this.lang,{
              headers: {
                Authorization: 'Bearer ' + authKey
              }
            })
            .then((response) => {
              this.challengesListItems = response.data;
            })
            .catch((e) => {
              this.errors.push(e);
            }).finally((e) => {
          (this.challengesListItems.length > 0) ? this.showChallengesList = true : this.showChallengesList = false;
        });
      }
    }
  },
  created() {
    let authKey = window.$cookies.get("authKey");
    this.$root.pageTitle = this.$root.translations.desafios;

    axios
        .get(process.env.VUE_APP_API_URL + 'products?id_category=4&lang=' + this.lang,{
          headers: {
            Authorization: 'Bearer ' + authKey
          }
        })
        .then((response) => {
          this.challengesListItems = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {
      (this.challengesListItems.length > 0) ? this.showChallengesList = true : this.showChallengesList = false;
    });
  },
}
</script>